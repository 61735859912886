@import "../../stylesheets/_colors.scss";

.full-screen-loader {
  z-index: 9;
  height: 100vh;
  width: 100vw;
  position: fixed !important;
  top: 0;
  left: 0;
  & > div {
    background: rgba(0, 0, 0, 0.5);

    ._loading_overlay_spinner {
      svg {
        circle {
          stroke: $accent;
        }
      }
    }
  }
}

@import "../../stylesheets/_colors.scss";
@import "../../stylesheets/_variables.scss";

.button {
  padding: 15px;
  box-sizing: border-box;
  background: map-get($map: $blue, $key: '0');
  color: $white;
  border-radius: 8px;
  border: 0;
  font-family: $font-family-primary, sans-serif;
  font-weight: $font-weight-500;
  font-size: $font-size-14;
  text-align: center;
  cursor: pointer;
  &.primary {
    &:disabled {
      background: map-get($map: $gray, $key: "5");
      color: map-get($map: $gray, $key: "0");
      border-color: map-get($map: $gray, $key: "0");
    }
  }
  &.secondary {
    color: map-get($map: $blue, $key: '0');
    border: solid 1px map-get($map: $blue, $key: '0');
    background: $white;
    &:disabled {
      background: $white;
      color: map-get($map: $gray, $key: "5");
      border-color: map-get($map: $gray, $key: "5");
    }
  }
  &:disabled {
    cursor: auto;
  }
}

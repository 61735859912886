$font-family-primary: "Lexend";

//***************font-weights***************//
$font-weight-200: 200;
$font-weight-300: 300;
$font-weight-400: 400;
$font-weight-500: 500;
$font-weight-600: 600;
$font-weight-700: 700;
$font-weight-800: 800;
$font-weight-900: 900;
$font-weight-normal: normal;
$font-weight-bold: bold;

//***************font-sizes***************//
$font-size-72: 72px;
$font-size-60: 60px;
$font-size-48: 48px;
$font-size-36: 36px;
$font-size-32: 32px;
$font-size-28: 28px;
$font-size-24: 24px;
$font-size-20: 20px;
$font-size-18: 18px;
$font-size-16: 16px;
$font-size-14: 14px;
$font-size-12: 12px;
$font-size-10: 10px;
$font-size-8: 8px;

$box-shadow-container: 0px 0px 24px 1px rgba(0, 0, 0, 0.2);

$popup-overlay: rgba(235, 235, 235, 0.88);

$container-width: 128rem;
@import "../../stylesheets/_mediaQuery.scss";
@import "../../stylesheets/_variables.scss";
@import "../../stylesheets/_colors.scss";
@import "../../stylesheets/_mixins.scss";

#rewardsHome {
  background-color: map_get($map: $purple, $key: '5');

  .introduction-bg-container {
    background-image: url(../../images/bg_rewardsInfo_introduction.png);
    background-size: cover;
  }

  .introduction {
    max-width: $container-width;
    box-sizing: border-box;
    margin: 0 auto;
    overflow: scroll;
    padding: 17.4rem 14.2rem 6rem 14.2rem;
    flex-grow: 1;

    .content {
      flex-grow: 1;
      margin-right: 13rem;

      .pre-heading {
        font-size: $font-size-24;
        font-weight: $font-weight-500;
        color: map-get($map: $gray, $key: '5');
      }

      .heading {
        margin-top: 1.2rem;
        font-size: $font-size-72;
        font-weight: $font-weight-800;
      }

      .post-heading {
        margin-top: 2.4rem;
        font-size: $font-size-18;
        color: map-get($map: $gray, $key: '5');
      }

      button {
        margin-top: 2rem;
        font-size: $font-size-16;
        padding: 1.6rem;
      }
    }

    .illustration {
      flex-grow: 1;

      img {
        width: 100%;
      }
    }
  }

  .how-to-earn {
    max-width: $container-width;
    box-sizing: border-box;
    margin: 0 auto;
    overflow: scroll;
    padding: 6rem 4rem;

    .content-container {
      display: flex;
      padding: 10rem;
      border-radius: 40px;
      background: url(../../images/bg_how_can_i_earn.png);
      color: $white;


      .left-content {
        width: 20%;
        font-size: $font-size-36;
        font-weight: $font-weight-800;
      }

      .right-content {
        background-image: linear-gradient(#39BDCD, #FFFFFF);
        -webkit-background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-text-fill-color: transparent;
        width: 80%;
        margin-left: 14rem;
        line-height: 1.5;
        font-size: $font-size-24;
      }

      .highlight {
        padding: 0 0.4rem;
        background-color: map-get($map: $blue, $key: '0');
        border-radius: 8px;
        -webkit-text-fill-color: lightblue;
        -webkit-text-fill-color: lightblue;
      }
    }
  }

  .how-it-works-bg-container {
    background-image: url(../../images/bg_rewardsInfo_howItWorks.png);
    background-size: cover;
  }

  .how-it-works {
    max-width: $container-width;
    box-sizing: border-box;
    margin: 0 auto;
    overflow: scroll;
    padding: 6rem 14.2rem;
    text-align: center;

    .heading {
      font-size: $font-size-36;
      font-weight: $font-weight-800;
    }

    .steps-container {
      margin-top: 7.8rem;
    }

    .step {
      display: flex;
      padding: 5rem 0;
      border-bottom: solid 1px map-get($map: $purple, $key: '10');

      &:first-child {
        padding-top: 0;
      }

      .step-content {
        display: flex;

        .arrow {
          margin-right: 4.4rem;
        }

        .step-text {
          margin-right: 10rem;
          text-align: left;


          .index {
            font-size: $font-size-18;
            font-weight: $font-weight-500;
            color: map-get($map: $gray, $key: '5');
          }

          .step-title {
            margin-top: 1.2rem;
            font-size: $font-size-24;
            font-weight: $font-weight-600;
          }

          .step-subtitle {
            margin-top: 1.2rem;
            font-size: $font-size-16;
            color: map-get($map: $gray, $key: '15');
          }
        }
      }

      .step-illustration {
        align-self: center;

        img,
        svg {
          height: 17.3rem;
        }
      }
    }
  }

  .benefits-bg-container {
    background-image: url(../../images/bg_rewardsInfo_benefits.png);
    background-size: cover;
  }

  .benefits {
    max-width: $container-width;
    box-sizing: border-box;
    margin: 0 auto;
    overflow: scroll;
    padding: 6rem 14.2rem;
    text-align: center;

    .heading {
      font-size: $font-size-36;
      font-weight: $font-weight-800;
    }

    .post-heading {
      margin-top: 0.8rem;
      font-size: $font-size-14;
      color: map-get($map: $gray, $key: '5');
    }

    .benefit-cards {
      margin-top: 4rem;
      display: flex;
      flex-wrap: wrap;
      gap: 2rem;

      .benefit-card {
        width: 48.8rem;
        padding: 2rem;
        box-sizing: border-box;
        border-radius: 24px;
        background-color: $white;
        text-align: left;

        .illustration-container {
          width: 100%;
          position: relative;

          img {
            width: 100%;
            border-radius: 24px;
          }

          .index {
            position: absolute;
            left: 2rem;
            top: 2rem;
            font-size: $font-size-16;
            font-weight: $font-weight-800;
            color: $white;
          }
        }

        .title {
          margin-top: 2rem;
          padding-left: 2rem;
          padding-right: 2rem;
          font-size: $font-size-24;
          font-weight: $font-weight-600;
        }

        .subtitle {
          margin-top: 0.4rem;
          padding-left: 2rem;
          padding-right: 2rem;
          font-size: $font-size-14;
          color: map-get($map: $gray, $key: '5');
        }
      }
    }

    .note {
      margin-top: 4rem;
      font-size: $font-size-14;
      color: map-get($map: $gray, $key: '5');
    }
  }

  .faqs-bg-container {
    background-image: url(../../images/bg_rewardsInfo_faqs.png);
    background-size: cover;
  }

  .faqs {
    max-width: $container-width;
    box-sizing: border-box;
    margin: 0 auto;
    overflow: scroll;
    padding: 6rem 14.2rem;
    flex-direction: column;
    text-align: center;

    a {
      text-decoration: none;
      color: map-get($map: $blue, $key: '0');
    }

    .heading {
      font-size: $font-size-36;
      font-weight: $font-weight-800;
    }

    .post-heading {
      margin-top: 0.8rem;
      width: 60%;
      font-size: $font-size-14;
      color: map-get($map: $gray, $key: '5');
    }

    .faqs-collapsibles {
      margin-top: 6rem;
      width: 100%;

      .Collapsible {
        margin-bottom: 2rem;
      }
    }
  }

  @media #{$mobile} {
    .introduction {
      padding: 0 0 0 0;
      flex-direction: column-reverse;
      margin: auto;
      text-align: center;

      .content {
        margin: auto;
        padding: 0rem 5.2rem;

        .pre-heading {
          font-size: $font-size-16;
        }

        .heading {
          font-size: $font-size-36;
        }

        .post-heading {
          font-size: $font-size-14;
        }
      }
    }

    .how-to-earn {
      padding: 8rem 1rem;

      .content-container {
        padding: 6.6rem 3rem;
        flex-direction: column;

        .left-content {
          width: auto;
          font-size: $font-size-24;
        }

        .right-content {
          width: auto;
          margin: auto;
          padding-top: 3.5rem;
          font-size: $font-size-18;
        }
      }
    }

    .how-it-works {
      padding: 1rem 3.0rem;

      .step {
        flex-direction: column-reverse;
      }
    }

    .benefits {
      padding: 6rem 1.0rem;

      .heading {
        font-size: $font-size-24;
      }

      .post-heading {
        font-size: $font-size-12;
      }

      .benefit-cards {
        .benefit-card {
          .title {
            font-size: $font-size-18;
          }
        }
      }

      .note {
        margin-top: 2rem;
      }
    }

    .faqs {
      padding: 6rem 1rem;

      .heading {
        padding: 0rem 1rem;
      }

      .post-heading {
        margin-top: 0.8rem;
        width: 83%;
      }
    }

    .faqs-collapsibles {
      margin-top: 4rem;
      text-align: left;
    }
  }
}
@import '../stylesheets/_mediaQuery.scss';
@import '../stylesheets/_colors.scss';
@import './_variables.scss';
html {
  font-size: 62.5%;
}

body {
  margin: 0;
  font-family: $font-family-primary, sans-serif;
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  color: map-get($map: $gray, $key: '0');
  .cicular-loader {
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }

  .horizontal-divider {
    margin: 2rem 0;
    border-top: 1px solid map_get($purple, '0');
  }

  &.no-scroll {
    overflow: hidden;
  }

  .container {
    margin: auto;
    padding: 0 16px;
    overflow: scroll;
  }

  .text-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  a {
    color: map-get($map: $gray, $key: '0');
  }

  @media #{$mobile} {
    .hide-in-mobile,
    .hide-in-mobile-and-tablet {
      display: none !important;
    }
  }
  @media screen and #{$tablet} {
    .hide-in-mobile-and-tablet {
      display: none !important;
    }
  }
  @media #{$larger-than-mobile} {
    .show-only-in-mobile {
      display: none !important;
    }
    .container {
      max-width: $container-width;
    }
  }
}

.flex {
  display: flex;
}

.flex-center-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-start-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flex-end-center {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flex-start-start {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-space-between-start {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.flex-space-between-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and #{$larger-than-tablet} {
  .hide-in-larger-than-tablet {
    display: none !important;
  }
}

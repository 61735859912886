@import '../../stylesheets/_colors.scss';
@import '../../stylesheets/_variables.scss';
@import '../../stylesheets/_mediaQuery.scss';

.snackbar-container {
  position: fixed;
  bottom: 76px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  .snackbar {
    width: 90%;
    border: 1px solid rgba(255, 255, 255, 0.5);
    box-sizing: border-box;
    border-radius: 8px;
    display: inline-flex;
    align-items: center;
    padding: 0.8rem 1.2rem;
    font-size: $font-size-12;
    font-weight: $font-weight-400;
    color: $white;
    &.error {
      background: map_get($red, '0');
      opacity: 1;
    }
    &.success {
      background: map_get($green, '0');
    }
    &.info {
      background-color: map-get($map: $gray, $key: '5');
      color: map-get($map: $gray, $key: '0');
    }
    svg {
      margin-right: 1.2rem;
      vertical-align: middle;
    }
  }

  @media #{$larger-than-mobile} {
    .snackbar {
      width: 300px;
      width: unset;
      max-width: 500px;
    }
  }
}

$accent: #ff42a5;
$gray: (
  '0': #341664,
  '5': #6D6795,
  '10': #341664,
  '15': #8681A5,
);

$blue: (
  '0': #5029B7,
);

$red: (
  '0': #de5a48,
);

$green: (
  '0': #b3d661,
  '5': #39BDCD,
);

$purple: (
  '0': #EAD7FF,
  '5':  #F4F3FF,
  '10': #C6CBFF,
  '15': #E9E8FF,
  '20': #C699FF,
);

$white: #ffffff;
$black: #000000;

@import '../../stylesheets/_mixins.scss';
@import '../../stylesheets/_mediaQuery.scss';
@import '../../stylesheets/_colors.scss';
@import '../../stylesheets/_variables.scss';

.Collapsible {
  .Collapsible__trigger {
    @include font-style($font-weight-400, $font-size-18, 21, map_get($gray, '0'));
    .accordian {
      background: white;
      border-radius: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      padding: 2rem 1.6rem;
      
      .accordian-arrow {
        margin-left: 2rem;
      }
    }
    &.is-open {
      .accordian {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
  .Collapsible__contentOuter {
    .Collapsible__contentInner {
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      background: white;
      span {
        display: flex;
        padding: 0 16px 16px 16px;
        @include font-style($font-weight-400, $font-size-14, 21, map_get($gray, '5'));
      }
    }
  }
  &:last-child {
    .accordian {
      border-bottom: unset;
    }
  }
}

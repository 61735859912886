@import "../../stylesheets/_mediaQuery.scss";
@import "../../stylesheets/_variables.scss";
@import "../../stylesheets/_colors.scss";
@import "../../stylesheets/_mixins.scss";

#login {
  background-color: map-get($map: $purple, $key: '20');
  background-image: url(../../images/bg_login.png);
  background-size: cover;
  background-repeat: no-repeat;
  .container {
    height: 100vh;
  }
  .login-box {
    border-radius: 24px;
  }
  .content-area {
    position: relative;
    bottom: -2.5rem;
    width: 40rem;
    padding: 4rem 2.8rem;
    text-align: center;
    border-radius: 24px;
    background-color: $white;
    box-shadow: $box-shadow-container;
    .title {
      font-size: $font-size-24;
      font-weight: $font-weight-600;
    }
    .sub-title {
      margin-top: 0.8rem;
      font-size: $font-size-16;
      color: map-get($map: $gray, $key: '5');
    }
    .login-options {
      margin: 4rem 0 2rem 0;
      button {
        padding: 1.3rem 0;
        margin-bottom: 2rem;
        &:last-child {
          margin-bottom: 0;
        }
        .login-icon {
          height: 24px;
          width: 24px;
          margin-right: 0.8rem;
        }
      }
    }

    .tnc, .learn-more {
      font-size: $font-size-12;
      font-weight: $font-weight-300;
      color: map-get($map: $gray, $key: '5');
      .hyperlink {
        color: map-get($map: $gray, $key: '10');
      }
    }

    button {
      width: 100%;
    }
  }
  .learn-more {
    padding: 5rem 2.8rem 2.5rem 2.8rem;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    font-size: $font-size-14;
    font-weight: $font-weight-600;
    background-color: map-get($map: $purple, $key: '15');
    background-image: url(../../images/bg_login_rewards.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
    a { 
      display: inline-flex;
      align-items: center;
      color: map-get($map: $blue, $key: '0');
      text-decoration: none;
      .rewards-logo {
        margin-right: 0.8rem;
      }
      .redirection-arrow {
        margin-left: 0.4rem;
      }
    }
  }
}

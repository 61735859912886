@import "../../stylesheets/_mediaQuery.scss";
@import "../../stylesheets/_variables.scss";
@import "../../stylesheets/_colors.scss";
@import "../../stylesheets/_mixins.scss";

#deviceConnectionSuccess {
  height: 100vh;
  background-color: map-get($map: $purple, $key: '20');
  background-image: url(../../images/bg_login.png);
  background-size: cover;
  background-repeat: no-repeat;
  .container {
    height: 100vh;
  }
  .content-area {
    width: 40rem;
    padding: 4rem 2.8rem;
    text-align: center;
    border-radius: 24px;
    background-color: $white;
    box-shadow: $box-shadow-container;
    .title {
      font-size: $font-size-24;
      font-weight: $font-weight-600;
    }

    .illustration, .note, button {
      margin-top: 2.4rem;
    }

    .illustration {
      width: 100%;
      border-radius: 12px;
    }

    button {
      width: 100%;
    }

    .note {
      font-size: $font-size-16;
      color: map-get($map: $gray, $key: '5');
    }

  }
}
